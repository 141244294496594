.Footer{
    background-color: #023750;
    color: white;
    text-align: center;
    padding: 2rem;
}

.Footer-icon{
    padding-left: 15px;
    font-size: 20px;
}
.Link{
    color: white;
}