.varaamaja{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 3rem;
}

.imagecontainer{
    width: 300px;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.imagecontainer img{
    width: 100%;
    margin: 1rem;
}

.Contact-container > p{

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;

}

.Contact-container > button{

    color: white;
    border: 2px solid white;
    padding: 1rem;
    margin: 1rem;
    font-size: 1rem;
    background-color: #023750;

}

.Contact-container > button:hover{

    background-color: white;
    color: #023750;

}