.vuokraa-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.Tarina{
    margin-top: 5rem;
}