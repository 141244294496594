.Tarina {
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.Tarina-img img {
    width: calc(20rem + 20vmin);
}

.Tarina-textcontainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    max-width: 90rem;
    font-size: calc(7px + 1.5vmin);
    margin: 3rem;
    padding-bottom: calc(17px + 3vmin);
}

.Tarina-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    max-width: 90rem;
    padding: 1rem;
}

.Tarina-text h2{
    font-size: calc(15px + 3vmin);;
}

.Tarina-text p{
    text-align: justify;
    line-height: 150%;
}

.Tarina-allekirjoituscontainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: calc(10px + 1vmin);
    margin: 1rem;
}

.Tarina-allekirjoituscontainer img{
    padding: 0;
    width: 150px;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.Tarina-allekirjoituscontainer p {
    margin: 0;
    justify-content: center;
    align-items: center;
}

.tarina-allekirjoitus{
    min-width: calc(15rem + 3vmin);
    height: auto;
}

.Button-teemat{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 20vmin;
    height: 4vmin;
    background-color: #023750;
    border: 2px solid white;
    color: white;
    padding: 1rem;
    font-size: 2vmin;
    text-decoration: none;
}

.Button-teemat:hover{
    cursor: pointer;
    background-color: white;
    border: 2px solid #023750;
    color: #023750;
}

.Buttonteema-icon{
    padding: 0.2rem;
    margin-left: 0.5rem;
  }