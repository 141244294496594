.Menu1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #023750;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
}

.Menu2 {
    display: flex;
    flex-direction: column;
    background-color: #023750;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.Menuicon-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Menu-links{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    font-size: calc(10px + 2vmin);
    list-style-type: none;
    color: white;
    margin: 0;
    padding: 0.5rem;
    margin-left: 1rem;
}

.Menu1 p {
    color: white;
    margin-left: 2rem;
    font-size: calc(0.7rem + 1vmin);
    font-family: 'Abel', sans-serif;
    font-weight: 600;
    padding: 0;
}

.Menu-a {
    text-decoration: none;
    display: inline-block;
    position: relative;
    padding: calc(7px + 1.5vmin);
    color: white;
    margin: 0;
    padding: 7px;
    margin-right: 1rem;
}
.Menu1 li{
    list-style-type: none;  
}

.Menu1 li a {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: calc(0.7rem + 1vmin);
}

.Menu-home {
    text-decoration: none;
    display: inline-block;
    position: relative;
    color: white;
    margin: 0;
    padding: 0;
    margin-left: 1rem;
    font-size: calc(0.7rem + 1.3vmin);
    font-family: 'Abel', sans-serif;
    font-weight: 600;
}

.Menu-a:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: inline-block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: white;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
.Menu-a:hover:after { 
    width: 100%; 
    left: 0; 
  }

  .logoimg_menu{
      width: 30px;
      padding: 0.5rem;
  }

.menubars{
    color: white;
    font-size: 2rem;
    margin: 1rem;
}