.Lupaus{
    padding-top: calc(17px + 3vmin);
    color: white;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #023750;
    top: 0;
    padding-bottom: 5rem;
}

.Lupaus-blockcontainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 100%;
    color: white;
}

.Lupaus-block{
    padding: 1rem 1rem 2rem 1rem;
    max-width: 360px;
    font-size: calc(7px + 1.5vmin);
}

.Lupaus-block h2{
    text-align: center;
    font-size: calc(14px + 1.5vmin); 
}

.Lupaus-title{
    color: white;
    font-size: calc(10px + 2vmin);
}

.Lupaus-icon{
    padding-bottom: 10%;
    font-size: calc(40px + 8vmin);
    text-align: center;
}

