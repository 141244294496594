.head {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background: url("https://storage.googleapis.com/vaaputtajat/pirtinvirta1.jpg") no-repeat;
    background-attachment: local;
    background-position: 30% 40%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.App-header {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: calc(10px + 2vmin);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -2;
}

.logoimg {
    width: 10rem;
}

.Banner-slogan-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding-top: 5%;
    z-index: 2;
}

.Banner-slogan {
    opacity: 0;
    display: flex;
    justify-content: space-around;
    align-content: flex-end;
    align-items: flex-end;
    animation: darkbanner;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.Banner-slogan__name {
    opacity: 0;
    margin-left: calc(10px + 1vmin);
    margin-bottom: 0;
    text-align: center;
    font-size: calc(24px + 4vmin);
    -webkit-text-shadow: 0 0 10px gray;
    text-shadow: 0 0 10px gray;
    animation: darkbanner;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.Banner-slogan__slogan {
    opacity: 0;
    font-size: calc(10px + 2vmin);
    margin-left: calc(10px + 1vmin);
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0;
    -webkit-text-shadow: 0 0 10px gray;
    text-shadow: 0 0 10px gray;
    animation: darkbanner;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

.Banner-slogan__secondarycolor {
    opacity: 0;
    display: flex;
    align-items: center;
    align-content: center;
    color: #003e1f;
    margin-top: 0;
    width: calc(150px + 30vmin);
    ;
    animation: darkbanner;
    animation-duration: 1s;
    animation-delay: 3.5s;
    animation-fill-mode: forwards;
}

.logoBanner {
    opacity: 0;
    max-width: calc(100px + 20vmin);
    margin-top: calc(40px + 6vmin);
    animation: darkbanner;
    animation-duration: 1s;
    animation-delay: 5s;
    animation-fill-mode: forwards;
}

.ehdokasnumero_banner {
    width: 200px;
    margin-left: 3rem;
}

@keyframes darkbanner {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
    }
}


@keyframes opacity {
    0% {
        diplay: none;
        opacity: 0;
    }

    20% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@media (max-width: 1200px) {
    .logoimg {
        width: 8%;
        min-width: 80px;
    }
}