.Tarina{

    min-height: 100vh;

}

.Tarina-text{

    align-items: flex-start;

}

.yhteys-button{

    align-items: center;
    text-align: center;

}

.yhteys-button > button {

    color: white;
    border: 2px solid #023750 ;
    padding: 1rem;
    margin: 1rem;
    margin-top: 2rem;
    font-size: 1rem;
    background-color: #023750;

}


.obfuscate, .obfuscate > *{

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;

}

.text-area{

    margin-top: 2rem;

}