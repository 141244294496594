.Contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 300px;
    padding: 2%;
    border: 1px solid black;
    -webkit-box-shadow: 0 0 10px gray;
    box-shadow: 0 0 10px gray;
    background-color: #023750;
    color: white;
    margin: 0.5rem;
}

.Contact-title{
    font-size: calc(20px + 1vmin);
}

.Contact-container{
    font-size: calc(10px + 1vmin);
    width: 90%;
}

.Contact-container h2{
    font-size: calc(20px + 0.8vmin);
}

.Contact-container h3{
    font-size: calc(18px + 0.6vmin);
}

.Contact-tietosuoja{
    width: 90%;
    font-size: calc(7px + 1vmin);
    font-style: italic;
}

.Majalista{
    list-style: none;
    padding: 0;
}

.Button-maja div{
    color: white;
    border: 2px solid white;
    padding: 1rem;
    margin: 1rem;
    font-size: 1rem;
}

.Button-maja{
    text-decoration: none;
}

.Button-maja div:hover{
    cursor: pointer;
    background-color: white;
    color: #023750;
}