.Tarina {
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.Tarina-img img {
    width: calc(20rem + 20vmin);
}

.Tarina-textcontainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    max-width: 90rem;
    font-size: calc(7px + 1.5vmin);
    margin: 3rem;
    padding-bottom: calc(17px + 3vmin);
}

.Tarina-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    max-width: 90rem;
    padding: 1rem;
}

.Tarina-text h1{
    font-size: calc(15px + 3vmin);
}

.Pirtinvirta-allekirjoituscontainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: calc(10px + 1vmin);
    margin: 1rem;
}

.Pirtinvirta-allekirjoituscontainer img{
    padding: 0;
    max-width: 20rem;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.Tarina-allekirjoituscontainer p {
    margin: 0;
    justify-content: center;
    align-items: center;
}

.Pirtinvirta-textcontainer{
    max-width: 600px;
    margin: 2rem;
}

.facebook-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
}

.otsikko-facebook{
    max-width: 20rem;
    font-size: calc(10px + 1vmin);
}