@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;600&family=Secular+One&display=swap);
body, button {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: blue;
  text-decoration: none;
}
.App, button{
  font-family: 'Rubik', sans-serif;
  weight: 400;
}

h1{
  font-family: 'Secular One', sans-serif;
}
.Tarina {
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.Tarina-img img {
    width: calc(20rem + 20vmin);
}

.Tarina-textcontainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    max-width: 90rem;
    font-size: calc(7px + 1.5vmin);
    margin: 3rem;
    padding-bottom: calc(17px + 3vmin);
}

.Tarina-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    max-width: 90rem;
    padding: 1rem;
}

.Tarina-text h2{
    font-size: calc(15px + 3vmin);;
}

.Tarina-text p{
    text-align: justify;
    line-height: 150%;
}

.Tarina-allekirjoituscontainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: calc(10px + 1vmin);
    margin: 1rem;
}

.Tarina-allekirjoituscontainer img{
    padding: 0;
    width: 150px;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.Tarina-allekirjoituscontainer p {
    margin: 0;
    justify-content: center;
    align-items: center;
}

.tarina-allekirjoitus{
    min-width: calc(15rem + 3vmin);
    height: auto;
}

.Button-teemat{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 20vmin;
    height: 4vmin;
    background-color: #023750;
    border: 2px solid white;
    color: white;
    padding: 1rem;
    font-size: 2vmin;
    text-decoration: none;
}

.Button-teemat:hover{
    cursor: pointer;
    background-color: white;
    border: 2px solid #023750;
    color: #023750;
}

.Buttonteema-icon{
    padding: 0.2rem;
    margin-left: 0.5rem;
  }
.head {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background: url("https://storage.googleapis.com/vaaputtajat/pirtinvirta1.jpg") no-repeat;
    background-attachment: local;
    background-position: 30% 40%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.App-header {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    font-size: calc(10px + 2vmin);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -2;
}

.logoimg {
    width: 10rem;
}

.Banner-slogan-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding-top: 5%;
    z-index: 2;
}

.Banner-slogan {
    opacity: 0;
    display: flex;
    justify-content: space-around;
    align-content: flex-end;
    align-items: flex-end;
    -webkit-animation: darkbanner;
            animation: darkbanner;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.Banner-slogan__name {
    opacity: 0;
    margin-left: calc(10px + 1vmin);
    margin-bottom: 0;
    text-align: center;
    font-size: calc(24px + 4vmin);
    -webkit-text-shadow: 0 0 10px gray;
    text-shadow: 0 0 10px gray;
    -webkit-animation: darkbanner;
            animation: darkbanner;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.Banner-slogan__slogan {
    opacity: 0;
    font-size: calc(10px + 2vmin);
    margin-left: calc(10px + 1vmin);
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0;
    -webkit-text-shadow: 0 0 10px gray;
    text-shadow: 0 0 10px gray;
    -webkit-animation: darkbanner;
            animation: darkbanner;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.Banner-slogan__secondarycolor {
    opacity: 0;
    display: flex;
    align-items: center;
    align-content: center;
    color: #003e1f;
    margin-top: 0;
    width: calc(150px + 30vmin);
    ;
    -webkit-animation: darkbanner;
    ;
            animation: darkbanner;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-delay: 3.5s;
            animation-delay: 3.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.logoBanner {
    opacity: 0;
    max-width: calc(100px + 20vmin);
    margin-top: calc(40px + 6vmin);
    -webkit-animation: darkbanner;
            animation: darkbanner;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-delay: 5s;
            animation-delay: 5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.ehdokasnumero_banner {
    width: 200px;
    margin-left: 3rem;
}

@-webkit-keyframes darkbanner {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        transition: opacity 300ms, -webkit-transform 300ms;
        transition: opacity 300ms, transform 300ms;
        transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
    }
}

@keyframes darkbanner {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        transition: opacity 300ms, -webkit-transform 300ms;
        transition: opacity 300ms, transform 300ms;
        transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
    }
}


@-webkit-keyframes opacity {
    0% {
        diplay: none;
        opacity: 0;
    }

    20% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}


@keyframes opacity {
    0% {
        diplay: none;
        opacity: 0;
    }

    20% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@media (max-width: 1200px) {
    .logoimg {
        width: 8%;
        min-width: 80px;
    }
}
.CTA{
    padding-top: calc(17px + 3vmin);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    margin: 7%;
    padding: 5%, 0, 5%, 0;
}

.CTA-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.Seuraa {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: white;
    box-shadow: 0 0 2rem black;
    margin: 0.5rem;
    padding: 1rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.Seuraa h1{
    text-align: center;
    font-size: calc(12px + 2.5vmin);
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.Seuraa h2{
    text-align: center;
    font-size: calc(10px + 1.5vmin);
    color: #023750;
    margin: 0.5rem;
    padding-top: 1rem;
}

.Seuraa h3{
    margin: 0.5rem;
}

.Seuraa-alt{
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
}

.valiviiva{
    margin:0;
    margin-top: 2rem;
    padding: 0;
    height: 4px;
    width: 300px;
    background-color: black;
}

.seuraa-container{
    margin: 1rem;
    
}

.Seuraa-iconcontainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.Seuraa-icon {
    color: white;
    font-size: calc(10px + 4.5vmin);
    margin: 0.5rem;
}

.Seuraa-teksti{
    font-size: calc(10px + 2vmin);
    text-align: center;
}

.Profile-img-container {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid white;    
}

.Profile-img-container img{
    width: 10rem;
}

.Profile-text{
    text-align: center;
    width: 250px;
}
.Contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 300px;
    padding: 2%;
    border: 1px solid black;
    box-shadow: 0 0 10px gray;
    background-color: #023750;
    color: white;
    margin: 0.5rem;
}

.Contact-title{
    font-size: calc(20px + 1vmin);
}

.Contact-container{
    font-size: calc(10px + 1vmin);
    width: 90%;
}

.Contact-container h2{
    font-size: calc(20px + 0.8vmin);
}

.Contact-container h3{
    font-size: calc(18px + 0.6vmin);
}

.Contact-tietosuoja{
    width: 90%;
    font-size: calc(7px + 1vmin);
    font-style: italic;
}

.Majalista{
    list-style: none;
    padding: 0;
}

.Button-maja div{
    color: white;
    border: 2px solid white;
    padding: 1rem;
    margin: 1rem;
    font-size: 1rem;
}

.Button-maja{
    text-decoration: none;
}

.Button-maja div:hover{
    cursor: pointer;
    background-color: white;
    color: #023750;
}
.varaamaja{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 3rem;
}

.imagecontainer{
    width: 300px;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.imagecontainer img{
    width: 100%;
    margin: 1rem;
}

.Contact-container > p{

    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;

}

.Contact-container > button{

    color: white;
    border: 2px solid white;
    padding: 1rem;
    margin: 1rem;
    font-size: 1rem;
    background-color: #023750;

}

.Contact-container > button:hover{

    background-color: white;
    color: #023750;

}
.Footer{
    background-color: #023750;
    color: white;
    text-align: center;
    padding: 2rem;
}

.Footer-icon{
    padding-left: 15px;
    font-size: 20px;
}
.Link{
    color: white;
}
.Lupaus{
    padding-top: calc(17px + 3vmin);
    color: white;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #023750;
    top: 0;
    padding-bottom: 5rem;
}

.Lupaus-blockcontainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 100%;
    color: white;
}

.Lupaus-block{
    padding: 1rem 1rem 2rem 1rem;
    max-width: 360px;
    font-size: calc(7px + 1.5vmin);
}

.Lupaus-block h2{
    text-align: center;
    font-size: calc(14px + 1.5vmin); 
}

.Lupaus-title{
    color: white;
    font-size: calc(10px + 2vmin);
}

.Lupaus-icon{
    padding-bottom: 10%;
    font-size: calc(40px + 8vmin);
    text-align: center;
}


.Menu1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #023750;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
}

.Menu2 {
    display: flex;
    flex-direction: column;
    background-color: #023750;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.Menuicon-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Menu-links{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    font-size: calc(10px + 2vmin);
    list-style-type: none;
    color: white;
    margin: 0;
    padding: 0.5rem;
    margin-left: 1rem;
}

.Menu1 p {
    color: white;
    margin-left: 2rem;
    font-size: calc(0.7rem + 1vmin);
    font-family: 'Abel', sans-serif;
    font-weight: 600;
    padding: 0;
}

.Menu-a {
    text-decoration: none;
    display: inline-block;
    position: relative;
    padding: calc(7px + 1.5vmin);
    color: white;
    margin: 0;
    padding: 7px;
    margin-right: 1rem;
}
.Menu1 li{
    list-style-type: none;  
}

.Menu1 li a {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: calc(0.7rem + 1vmin);
}

.Menu-home {
    text-decoration: none;
    display: inline-block;
    position: relative;
    color: white;
    margin: 0;
    padding: 0;
    margin-left: 1rem;
    font-size: calc(0.7rem + 1.3vmin);
    font-family: 'Abel', sans-serif;
    font-weight: 600;
}

.Menu-a:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: inline-block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: white;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
.Menu-a:hover:after { 
    width: 100%; 
    left: 0; 
  }

  .logoimg_menu{
      width: 30px;
      padding: 0.5rem;
  }

.menubars{
    color: white;
    font-size: 2rem;
    margin: 1rem;
}
.Tarina {
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.Tarina-img img {
    width: calc(20rem + 20vmin);
}

.Tarina-textcontainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    max-width: 90rem;
    font-size: calc(7px + 1.5vmin);
    margin: 3rem;
    padding-bottom: calc(17px + 3vmin);
}

.Tarina-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    max-width: 90rem;
    padding: 1rem;
}

.Tarina-text h1{
    font-size: calc(15px + 3vmin);
}

.Pirtinvirta-allekirjoituscontainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: calc(10px + 1vmin);
    margin: 1rem;
}

.Pirtinvirta-allekirjoituscontainer img{
    padding: 0;
    max-width: 20rem;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.Tarina-allekirjoituscontainer p {
    margin: 0;
    justify-content: center;
    align-items: center;
}

.Pirtinvirta-textcontainer{
    max-width: 600px;
    margin: 2rem;
}

.facebook-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
}

.otsikko-facebook{
    max-width: 20rem;
    font-size: calc(10px + 1vmin);
}
.vuokraa-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.Tarina{
    margin-top: 5rem;
}
.Tarina{

    min-height: 100vh;

}

.Tarina-text{

    align-items: flex-start;

}

.yhteys-button{

    align-items: center;
    text-align: center;

}

.yhteys-button > button {

    color: white;
    border: 2px solid #023750 ;
    padding: 1rem;
    margin: 1rem;
    margin-top: 2rem;
    font-size: 1rem;
    background-color: #023750;

}


.obfuscate, .obfuscate > *{

    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;

}

.text-area{

    margin-top: 2rem;

}
