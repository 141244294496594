.CTA{
    padding-top: calc(17px + 3vmin);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    margin: 7%;
    padding: 5%, 0, 5%, 0;
}

.CTA-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}