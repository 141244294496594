.Seuraa {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: white;
    -webkit-box-shadow: 0 0 2rem black;
    box-shadow: 0 0 2rem black;
    margin: 0.5rem;
    padding: 1rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.Seuraa h1{
    text-align: center;
    font-size: calc(12px + 2.5vmin);
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.Seuraa h2{
    text-align: center;
    font-size: calc(10px + 1.5vmin);
    color: #023750;
    margin: 0.5rem;
    padding-top: 1rem;
}

.Seuraa h3{
    margin: 0.5rem;
}

.Seuraa-alt{
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
}

.valiviiva{
    margin:0;
    margin-top: 2rem;
    padding: 0;
    height: 4px;
    width: 300px;
    background-color: black;
}

.seuraa-container{
    margin: 1rem;
    
}

.Seuraa-iconcontainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.Seuraa-icon {
    color: white;
    font-size: calc(10px + 4.5vmin);
    margin: 0.5rem;
}

.Seuraa-teksti{
    font-size: calc(10px + 2vmin);
    text-align: center;
}

.Profile-img-container {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid white;    
}

.Profile-img-container img{
    width: 10rem;
}

.Profile-text{
    text-align: center;
    width: 250px;
}